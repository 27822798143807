export const stack = [
  "HTML",
  "CSS",
  "JavaScript",
  "React",
  "React-Native",
  "Angular",
  "Nodejs/Express",
  "Jest/React-Testing",
];
